import {APP_CONSTS} from "../../../consts";
import {useDispatch} from "react-redux";
import React, {useEffect} from "react";
import {Checkbox, TextField} from "@fluentui/react";
import {localStoreThunk, useLocalStore} from "@dladio/hooks";
import {getIsDNShow, getIsPSShow, setItem} from "../../../services/local-storage.service";

const Filter = () => {
    const [isDNChecked, setIsDNChecked] = React.useState<boolean>(!!getIsDNShow());
    const [isPSChecked, setIsPSChecked] = React.useState<boolean>(!!getIsPSShow());
    const {setStore} = localStoreThunk("filters")
    const {setStore: setPrintix} = localStoreThunk("printix")
    const {model: queueStatus} = useLocalStore("queue-status")
    const dispatch: any = useDispatch()

    let printStatus = (queueStatus === APP_CONSTS.PRINT_QUEUE_STATUS.IN_PROGRESS)

    useEffect(() => {
        setIsDNChecked(!!getIsDNShow())
        setIsPSChecked(!!getIsPSShow())
    }, [])

    // Enabling SI in initial rendering
    useEffect(() => {
        setItem(APP_CONSTS.SHOW_DN, `true`)
        dispatch(setStore("isDN", true))
        setIsDNChecked(!!getIsDNShow())
    }, [])

    useEffect(() => {
        dispatch(setStore("isDN", isDNChecked))
        dispatch(setStore("isPS", isPSChecked))
    }, [isPSChecked, isDNChecked])

    const onChangeDN = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setIsDNChecked(!!checked);
            setItem(APP_CONSTS.SHOW_DN, `${checked}`)
            dispatch(setPrintix("jobs", []))
        }, [],
    );

    const onChangePi = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setIsPSChecked(!!checked);
            setItem(APP_CONSTS.SHOW_PS, `${checked}`)
            dispatch(setPrintix("jobs", []))
        }, [],
    );

    const onSearchChanges = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            if (!newValue || newValue.length <= 5) {
                dispatch(setStore("searchKey", (newValue || '')))
            }
        }, [],
    );

    return (
        <div className="flex w-full justify-between items-center pb-3 mt-2">
            <TextField disabled={printStatus} placeholder="Search" className="w-1/2" onChange={onSearchChanges}/>

            <div className="flex items-center gap-3">
                <Checkbox disabled={printStatus} label="DN" checked={isDNChecked} onChange={onChangeDN}/>
                <Checkbox disabled={printStatus} label="PS" checked={isPSChecked} onChange={onChangePi}/>
            </div>
        </div>
    )
}

export default Filter