import { erpMethodApi, erpResourceApi } from "@dladio/service";
import _ from "lodash";

interface IProp {
    path: string,
    options: any,
    meta?: any
}

const deriveModel = {
    'standard-list': erpResourceApi.endpoints.list.initiate,
    'standard': erpResourceApi.endpoints.get.initiate,
    'erp': erpMethodApi.endpoints.execMethodApi.initiate,
    'report': erpMethodApi.endpoints.execMethodApi.initiate
}

export const derive = (type: string, { options, meta = {}, path }: IProp) => {
    return async (dispatch: any) => {

        const query = _.get(deriveModel, type)

        const { data } = await dispatch(query(({ ...options }), ({ ...meta })))

        return _.get(data, path)
    }
}