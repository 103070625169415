import {Login} from "@microsoft/mgt-react";
import PrintQueue from "../pages/print-queue/PrintQueue";
import {useRoutes} from "react-router-dom";
import PrintQueueV2 from "../pages/print-queue-v2/PrintQueueV2";
import useIsSignedIn from "../hooks/use-is-signed-in";
import {CoreUIProvider} from "@dladio/core-ui";

const RouterConfigs = () => {
    const [isSignedIn] = useIsSignedIn();

    const element = useRoutes([
        {
            path: "/",
            element: (
                <CoreUIProvider>
                    {!isSignedIn ? <Login/> : (<PrintQueue/>)}
                </CoreUIProvider>
            )
        },
        {
            path: "/v2",
            element: (
                <CoreUIProvider>
                    {!isSignedIn ? <Login/> : (<PrintQueueV2/>)}
                </CoreUIProvider>
            )
        }
    ]);

    return element;
};

export default RouterConfigs;