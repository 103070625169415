import _ from "lodash";
import {BsPrinter} from "react-icons/bs";
import {APP_CONSTS} from "../../../consts";
import {useLocalStore} from "@dladio/hooks";
import {CheckCircleIcon, InfoIcon} from "@chakra-ui/icons";

const SelectedPrinter = ({setShowPrinterDialog}: any) => {
    const {WARNING, ONLINE, UNKNOWN} = APP_CONSTS.PRINTER_STATUS
    const {select} = useLocalStore("printix")
    let printer = select("selectedPrinter")

    const backgroundColor = printer?.connectionStatus === ONLINE ?
        "bg-green-200 border-green-400" : printer?.connectionStatus === WARNING ?
            "bg-yellow-200 border-yellow-400" : printer?.connectionStatus === UNKNOWN ?
                "bg-red-200 border-red-500" : "bg-orange-200 border-orange-400"

    const badgeColor = printer?.connectionStatus !== UNKNOWN ?
        "bg-green-500" : "bg-red-500"

    return (
        <div onClick={() => setShowPrinterDialog(true)}
             className={`w-full cursor-pointer rounded mt-2 border border-gray-400 flex justify-between items-center p-3 ${backgroundColor}`}>
            <div>
                <h1>{printer?.name || "Please select a printer"}</h1>
                <div className={"flex items-center gap-2 mt-2"}>
                    {!_.isEmpty(printer) &&
                        <h1 className={`text-xs text-white font-bold w-fit px-1 rounded ${badgeColor}`}>
                            {printer?.connectionStatus === UNKNOWN ? "OFFLINE" : ONLINE}
                        </h1>}

                    {
                        printer?.connectionStatus === ONLINE && (
                            <h1 className={`text-xs text-white font-bold w-fit px-1 rounded ${badgeColor}`}>
                                READY
                            </h1>
                        )
                    }
                </div>
            </div>
            <div className={"relative"}>
                <BsPrinter className={"w-8 h-8 "}/>
                {
                    printer?.connectionStatus === ONLINE ? (
                        <CheckCircleIcon color={"green.500"} className={"absolute -top-2 -right-2"}/>
                    ) : (
                        <InfoIcon color={"red.500"} className={"absolute -top-2 -right-2"}/>
                    )
                }
            </div>
        </div>
    )
}

export default SelectedPrinter