function setWindowKey(key: string, condition: boolean = false) {
    const _window = window as any;
    _window[key] = condition
}

function getWindowKey(key: string) {
    const _window = window as any;
    return _window[key] ?? false
}

export { setWindowKey, getWindowKey }