const GET_CURRENT_PRINT_QUEUE = "d45e97f1b1"
const GET_PRINT_QUEUE_LINES = "0205735976"
const GET_PRINTED_LIST = "83e8d866a7"
const GET_TODAY_QUEUE_DOC_ENTRY = "0205735976"
const GET_CURRENT_PRINT_QUEUE_V2 = "bcc3c1ed4f"
const GET_PRINTED_LIST_V2 = "356799eb27"

export default {
    GET_CURRENT_PRINT_QUEUE,
    GET_PRINT_QUEUE_LINES,
    GET_PRINTED_LIST,
    GET_TODAY_QUEUE_DOC_ENTRY,
    GET_CURRENT_PRINT_QUEUE_V2,
    GET_PRINTED_LIST_V2
}