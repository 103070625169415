import {QuestionOutlineIcon} from '@chakra-ui/icons'

const NoPrinters = () => {

    return (
        <div className={"p-3 w-full flex flex-col items-center"}>
            <QuestionOutlineIcon w={8} h={8} color={"red.400"}/>
            <p className={"mt-3"}>No printers found!</p>
        </div>
    )
}

export default NoPrinters