import _ from "lodash";
import axios from "axios";
import moment from "moment";
import {activityUpdater} from "./activity-updater.service";
import {SAP_PRINT_STATUS} from "../consts/sap-line-status";
import {checkTokensValidity} from "../utils";
import {APP_CONSTS, QUERIES} from "../consts";
import {erpMethodApi, SERVICE} from "@dladio/service";
import {getLocalStoreThunk, localStoreThunk} from "@dladio/hooks";
import {getAccessToken, getItem, getSubmitLink, getTenentToken, setItem} from "./local-storage.service";


function login() {
    const params = new URLSearchParams()
    params.append('grant_type', 'client_credentials')
    params.append('client_id', APP_CONSTS.PRINTIX_CLIENT_ID)
    params.append('client_secret', APP_CONSTS.PRINTIX_CLIENT_SECRET)

    const url = "https://auth.printix.net/oauth/token"

    axios.post(url, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then((result: any) => {

        if (!_.isEmpty(result)) {
            if (result.status === 200) {
                const {access_token, refresh_token} = result.data

                setItem(APP_CONSTS.ACCESS_TOKEN, access_token)
                setItem(APP_CONSTS.REFRESH_TOKEN, refresh_token)
                setItem(APP_CONSTS.EXPIRATION_TIME, String(new Date()))

                try {
                    getTenant()
                } catch (e: any) {
                    console.log(e.message)
                }

            } else {
                console.log("Printix login error")
            }

        } else {
            console.log("Printix login error")
        }
    }).catch((error: any) => {
        console.log(error.message)
    })
}

function getTenant() {
    const url = "https://api.printix.net/cloudprint"

    axios.get(url, {
        headers: {
            'Authorization': `Bearer ${getAccessToken()}`
        },
        withCredentials: true
    }).then((result: any) => {

        if (!_.isEmpty(result)) {
            if (result.status === 200) {
                try {
                    const tenantLink = result.data._links['teadrop.printix.net'].href
                    setItem(APP_CONSTS.TENANT_ID, tenantLink.split("/")[5])
                } catch (e: any) {
                    console.log(e.message)
                }

            } else {
                console.log("Unable to get tenant")
            }

        } else {
            console.log("Unable to get tenant")
        }
    }).catch((error: any) => {
        console.log(error.message)
    })
}

function getPrinters() {
    return async (dispatch: any) => {
        if (await checkTokensValidity()) {
            const tenantId = getTenentToken();
            const url = `https://api.printix.net/cloudprint/tenants/${tenantId}/printers`

            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken()}`
                },
                withCredentials: true
            }).then(async (result: any) => {
                if (!_.isEmpty(result)) {
                    if (result.status === 200) {
                        const {setStore} = localStoreThunk("printix")
                        const printers = result?.data?.printers ?? []
                        await dispatch(setStore("printers", printers))

                        const selectedPrinter: any = getItem(APP_CONSTS.DEFAULT_PRINTER)
                        let parsedSelectedPrinter = !_.isEmpty(selectedPrinter) ? JSON.parse(selectedPrinter) : {}

                        const findResult = _.find(printers, {"id": parsedSelectedPrinter["id"]})

                        if (!_.isEmpty(findResult)) {
                            if (findResult['connectionStatus'] !== parsedSelectedPrinter["connectionStatus"]) {
                                parsedSelectedPrinter["connectionStatus"] = findResult["connectionStatus"]
                                setItem(APP_CONSTS.DEFAULT_PRINTER, JSON.stringify(parsedSelectedPrinter))
                            }
                        }

                        await dispatch(setStore("selectedPrinter", parsedSelectedPrinter))
                    } else {
                        console.log("Unable to fet printer list")
                    }

                } else {
                    console.log("Unable to fet printer list")
                }
            }).catch((error: any) => {
                console.log(error.message)
            })
        } else {
            login()
        }
    }
}

function submitJob(title: String = moment().format("YYYY-MM-DD")) {
    return async (dispatch: any) => {
        if (await checkTokensValidity()) {
            const submitLink = getSubmitLink()
            const body = {
                "color": false,
                "duplex": "NONE",
                "page_orientation": "AUTO",
                "copies": 1,
                "media_size": "A4",
                "scaling": "NOSCALE"
            }

            const result = await axios.post(submitLink, body, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken()}`,
                    'version': '1.1',
                    'Content-Type': 'application/json'
                },
                params: {
                    title,
                    user: "dlad"
                },
                withCredentials: true
            })

            try {
                if (!_.isEmpty(result)) {
                    if (result.status === 200) {
                        const previousActivities: any = await dispatch(getLocalStoreThunk("printix", "jobs"))

                        const {setStore} = localStoreThunk("printix")
                        const completeLink = result.data._links.uploadCompleted.href
                        const uploadLink = result.data.uploadLinks[0].url

                        await dispatch(setStore("links", {
                            completeLink,
                            uploadLink
                        }))

                        const jobId = result.data.job.id
                        const {IN_PROGRESS: status} = APP_CONSTS.LOCAL_JOB_STATUS

                        if (!_.isEmpty(previousActivities)) {
                            await dispatch(setStore("jobs", [{jobId, title, status}, ...previousActivities]))
                        } else {
                            await dispatch(setStore("jobs", [{jobId, title, status}]))
                        }

                        dispatch(activityUpdater(jobId, APP_CONSTS.ACTIVITY_STATUS.JOB_CREATING_SUCCESS))

                        return {jobId, completeLink, uploadLink, status}

                    } else {
                        console.log("Unable to create a job")
                    }

                } else {
                    console.log("Unable to create a job")
                }
            } catch (e: any) {
                console.log("Unable to create a job ", e.me)
            }
        }
    }
}

function uploadFile(jobId: string, file: any, uploadLink: string) {
    return async (dispatch: any) => {
        const {FILE_UPLOADING_ERROR, FILE_UPLOADING_SUCCESS} = APP_CONSTS.ACTIVITY_STATUS
        if (await checkTokensValidity()) {

            const options = {
                method: 'POST',
                url: 'https://teadrop-epc.dlad.io/api/method/dlad_printix_app.api.upload_file',
                headers: {'Content-Type': 'application/json'},
                withCredentials: true,
                data: {
                    url: uploadLink,
                    payload: file
                },
            };

            let result = await axios.request(options)

            try {
                if (!_.isEmpty(result)) {
                    if (result.status === 200) {
                        dispatch(activityUpdater(jobId, FILE_UPLOADING_SUCCESS))
                        return true

                    } else {
                        dispatch(activityUpdater(jobId, FILE_UPLOADING_ERROR))
                        return false
                    }

                } else {
                    dispatch(activityUpdater(jobId, FILE_UPLOADING_ERROR))
                    return false
                }

            } catch (error: any) {
                dispatch(activityUpdater(jobId, FILE_UPLOADING_ERROR))
                return false
            }
        }
    }
}

function uploadFileV2(jobId: string, file: any, uploadLink: string, docEntry: any, type: any) {
    return async (dispatch: any) => {
        const {FILE_UPLOADING_ERROR, FILE_UPLOADING_SUCCESS} = APP_CONSTS.ACTIVITY_STATUS
        if (await checkTokensValidity()) {

            const options = {
                method: 'POST',
                url: 'https://core.teadrop.us/api/method/dlad_printix_app.api.upload_file_v2',
                headers: {'Content-Type': 'application/json'},
                withCredentials: true,
                data: {
                    url: uploadLink,
                    payload: file,
                    type,
                    docEntry
                },
            };

            let result = await axios.request(options)

            try {
                if (!_.isEmpty(result)) {
                    if (result.status === 200) {
                        dispatch(activityUpdater(jobId, FILE_UPLOADING_SUCCESS))
                        return true

                    } else {
                        dispatch(activityUpdater(jobId, FILE_UPLOADING_ERROR))
                        return false
                    }

                } else {
                    dispatch(activityUpdater(jobId, FILE_UPLOADING_ERROR))
                    return false
                }

            } catch (error: any) {
                dispatch(activityUpdater(jobId, FILE_UPLOADING_ERROR))
                return false
            }
        }
    }
}

function completeJob(jobId: any, link: any) {
    return async (dispatch: any) => {
        const {JOB_COMPLETION_ERROR, JOB_COMPLETION_SUCCESS} = APP_CONSTS.ACTIVITY_STATUS
        if (await checkTokensValidity()) {

            const result = await axios.post(link, {}, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken()}`,
                },
                withCredentials: true
            })

            try {
                if (!_.isEmpty(result)) {
                    if (result.status === 200) {
                        dispatch(activityUpdater(jobId, JOB_COMPLETION_SUCCESS))
                        return true

                    } else {
                        dispatch(activityUpdater(jobId, JOB_COMPLETION_ERROR))
                        return false
                    }

                } else {
                    dispatch(activityUpdater(jobId, JOB_COMPLETION_ERROR))
                    return false
                }

            } catch (e: any) {
                dispatch(activityUpdater(jobId, JOB_COMPLETION_ERROR))
                return false
            }
        }
    }
}

type TemplateTypes =
    SAP_PRINT_STATUS.JOB_CREATING_ERROR
    | SAP_PRINT_STATUS.JOB_DONE
    | SAP_PRINT_STATUS.JOB_ERROR
    | SAP_PRINT_STATUS.PDF_GENERATING_ERROR
    | SAP_PRINT_STATUS.UPLOADING_ERROR

function sapUpdater(template: TemplateTypes, error: any, DocEntry: any, LineId: any, jobId: any) {

    const templateSelector = () => {
        if (template == SAP_PRINT_STATUS.JOB_CREATING_ERROR) {
            return {
                "LineId": LineId,
                "U_JobID": jobId,
                "U_UploadStatus": "ERROR",
                "U_CompletionStatus": "ERROR",
                "U_JobStatus": "ERROR",
                "U_Errors": `Creating job error`
            }
        } else if (template == SAP_PRINT_STATUS.UPLOADING_ERROR) {
            return {
                "LineId": LineId,
                "U_JobID": jobId,
                "U_UploadStatus": "ERROR",
                "U_CompletionStatus": "ERROR",
                "U_JobStatus": "ERROR",
                "U_Errors": `File uploading error - ${error}`
            }
        } else if (template == SAP_PRINT_STATUS.PDF_GENERATING_ERROR) {
            return {
                "LineId": LineId,
                "U_JobID": jobId,
                "U_UploadStatus": "ERROR",
                "U_CompletionStatus": "ERROR",
                "U_JobStatus": "ERROR",
                "U_Errors": `PDF generating error - ${error}`
            }
        } else if (template == SAP_PRINT_STATUS.JOB_DONE) {
            return {
                "LineId": LineId,
                "U_JobID": jobId,
                "U_UploadStatus": "DONE",
                "U_CompletionStatus": "DONE",
                "U_JobStatus": "DONE",
            }
        } else {
            return {
                "LineId": LineId,
                "U_JobID": jobId,
                "U_UploadStatus": "DONE",
                "U_CompletionStatus": "ERROR",
                "U_JobStatus": "ERROR",
                "U_Errors": `Job completion error ${error ?? ""}`
            }
        }
    }

    return async (dispatch: any) => {
        const {data, error} = await dispatch(
            erpMethodApi.endpoints.execMutationApi.initiate({
                method: SERVICE.EXEC_SAP_SERVICE,
                body: {
                    method: "PATCH",
                    endpoint: `DPrintQueue(${DocEntry})`,
                    body: {
                        "DLAD_PRINTQ_LINESCollection": [templateSelector()]
                    }
                },
            }),
        );
    }
}

function getCurrentQueueDocEntry() {
    return async (dispatch: any) => {
        const {data, error} = await dispatch(
            erpMethodApi.endpoints.execMethodApi.initiate({
                method: SERVICE.EXEC_QUERY_METHOD,
                body: {
                    name: QUERIES.GET_TODAY_QUEUE_DOC_ENTRY,
                },
            }, {forceRefetch: true}),
        );

        if (!_.isEmpty(data) && !error) {
            return _.get(data, ["message", "value"] ?? -1)
        }
    }
}


export {login, getPrinters, submitJob, uploadFile, uploadFileV2, completeJob, sapUpdater, getCurrentQueueDocEntry};
