import _ from "lodash"
import {APP_CONSTS} from "../consts"
import {getLocalStoreThunk, localStoreThunk} from "@dladio/hooks"


const activityUpdater = (jobId: string, status: string = "default status") => {
    return async (dispatch: any) => {

        const {setStore} = localStoreThunk("printix")
        const jobs: any = await dispatch(getLocalStoreThunk("printix", "jobs"))

        const index = _.findIndex(jobs, {"jobId": jobId})
        const _jobs = _.cloneDeep(jobs)
        const _activities = _.cloneDeep(_.get(_jobs, [index, 'activities']))

        if (!_.isEmpty(_activities)) {
            await dispatch(setStore(`jobs[${index}].activities`, [..._activities, status]))
        } else {
            await dispatch(setStore(`jobs[${index}].activities`, [status]))
        }

        if (status?.includes("FAILED")) {
            await dispatch(setStore(`jobs[${index}].status`, APP_CONSTS.LOCAL_JOB_STATUS.FAILED))
        }

        if (status == APP_CONSTS.ACTIVITY_STATUS.JOB_COMPLETION_SUCCESS) {
            await dispatch(setStore(`jobs[${index}].status`, APP_CONSTS.LOCAL_JOB_STATUS.SUCCESS))
        }
    }
}

export {activityUpdater}