import _ from "lodash";
import Filter from "./components/filter/Filter";
import {QUERIES} from "../../consts";
import {useDerive} from "@dladio/service";
import {useEffect} from "react";
import InvoiceItem from "./components/InvoiceItem";
import multiSearch from "multi-search";
import {useDispatch} from "react-redux";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import {localStoreThunk, useLocalStore} from "@dladio/hooks";

const InvoiceList = () => {
    const dispatch: any = useDispatch()
    const {select} = useLocalStore("filters")
    const {setStore: set} = localStoreThunk("printQueue")
    let isPS = select("isPS")
    let isSI = select("isSI")
    let searchKey = select("searchKey")

    const response = useDerive('exec', {
        options: {
            method: EXEC_QUERY_METHOD,
            body: {
                name: QUERIES.GET_CURRENT_PRINT_QUEUE,
            }
        }, path: 'message'
    })

    let invoiceList = (!_.isEmpty(response) && response?.data) ? _.get(response, "data.message") : []

    const listSelector = () => {
        if (isPS && isPS != undefined && !isSI) {
            return _.filter(_.cloneDeep(invoiceList), {PrintType: "PS"})

        } else if (isSI && isSI != undefined && !isPS) {
            return _.filter(_.cloneDeep(invoiceList), {PrintType: "SI"})

        } else if (isPS && isSI) {
            return invoiceList

        } else {
            return []
        }
    }

    const selectedList = listSelector() ?? []

    //Need to review
    useEffect(() => {
        dispatch(set("printQueue", selectedList))
    }, [JSON.stringify(selectedList)])

    const invoiceMarkup = multiSearch(selectedList, searchKey ?? "").map((line: any, index: any) => <InvoiceItem
        key={index} {...line} />)

    return (
        <>
            <div className="bg-orange-200 mt-2 p-3 rounded border border-yellow-500">
                <h1>{`${selectedList?.length ?? 0} documents in the print queue`}</h1>
            </div>
            <Filter/>
            <div className={"flex flex-col mt-2 gap-2 h-[calc(100vh-260px)] overflow-x-hidden overflow-y-auto"}>
                {invoiceMarkup}
            </div>
        </>
    )
}

export default InvoiceList