import {getItem} from "../services/local-storage.service";
import {APP_CONSTS} from "../consts";

const checkDefaultPrinter = () => {
    const defaultPrinterDetails = getItem(APP_CONSTS.DEFAULT_PRINTER)

    if (defaultPrinterDetails) {
        return JSON.parse(defaultPrinterDetails)
    } else {
        return null
    }
}

export {checkDefaultPrinter}