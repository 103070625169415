import moment from "moment";
import {APP_CONSTS} from "../consts";

function setItem(key: string, value: string) {
    localStorage.setItem(key, value)
}

function getItem(key: string) {
    return localStorage.getItem(key)
}

function getAccessToken() {
    return getItem(APP_CONSTS.ACCESS_TOKEN)
}

function accessTokenIsValid() {
    if (getItem(APP_CONSTS.EXPIRATION_TIME)) {
        const startTime = moment(getItem(APP_CONSTS.EXPIRATION_TIME));
        const endTime = moment(new Date())
        const diff = moment.duration(endTime.diff(startTime)).asHours()

        if (diff < 0.9) return true
        else return false

    } else {
        return false
    }
}

function getTenentToken() {
    return getItem(APP_CONSTS.TENANT_ID)
}

function getSubmitLink() {
    const printerDetails = getItem(APP_CONSTS.DEFAULT_PRINTER)

    if (printerDetails) {
        const {submitLink: rowSubmitLink} = JSON.parse(printerDetails)
        const submitLink = rowSubmitLink.split("?")[0]

        return submitLink ? submitLink : null
    } else {
        return null
    }
}

function getIsPSShow() {
    const isShow = getItem(APP_CONSTS.SHOW_PS)

    if (isShow === 'true') return true
    else return false
}

function getIsSIShow() {
    const isShow = getItem(APP_CONSTS.SHOW_DN)

    if (isShow === 'true') return true
    else return false
}

function getIsDNShow() {
    const isShow = getItem(APP_CONSTS.SHOW_SI)

    if (isShow === 'true') return true
    else return false
}


export {
    setItem,
    getItem,
    getAccessToken,
    accessTokenIsValid,
    getTenentToken,
    getSubmitLink,
    getIsPSShow,
    getIsSIShow,
    getIsDNShow
}