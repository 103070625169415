import assign from "lodash/assign";
//@ts-ignore
import expressions from 'angular-expressions';
import PizZipUtils from "pizzip/utils/index.js";

const angularParser = (tag: any) => {
    if (tag === '.') {
        return {
            get: (s: any) => {
                return s;
            }
        };
    }
    const expr = expressions.compile(
        tag.replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"')
    );
    return {
        get: (scope: any, context: any) => {
            let obj = {};
            const scopeList = context.scopeList;
            const num = context.num;
            for (let i = 0, len = num + 1; i < len; i++) {
                obj = assign(obj, scopeList[i]);
            }
            return expr(scope, obj);
        }
    };
}

const loadFile = (url: any, callback: any) => {
    PizZipUtils.getBinaryContent(url, callback);
}


export { angularParser, loadFile }
