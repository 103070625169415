interface PrinterStatusProps {
    status: String,
    color: String
}

const PrinterStatus = ({status = "", color}: PrinterStatusProps) => {

    return (
        <p className={`text-xs ${color}`}>
            {status}
        </p>
    )
}

export default PrinterStatus
