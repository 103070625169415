import _ from "lodash";
import NoPrinters from "./components/NoPrinters";
import PrinterItem from "./components/PrinterItem";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getPrinters } from "../../services/printix-api.service";
import { useLocalStore } from "@dladio/hooks";
import { DefaultButton, Dialog, DialogFooter, DialogType } from "@fluentui/react";

interface PrinterListDialogProps {
    show: boolean,
    setShow: any
}

const dialogContentProps = {
    type: DialogType.normal,
    title: 'Select a Printer',
    closeButtonAriaLabel: 'Close',
};

const PrinterListDialog = ({ show, setShow }: PrinterListDialogProps) => {
    const dispatch = useDispatch()
    const { select } = useLocalStore("printix")
    let printerList = !_.isEmpty(select("printers")) ? select("printers") : []

    useEffect(() => {
        if (show) dispatch(getPrinters() as any)
    }, [show])

    const closeHandler = () => {
        setShow(false)
    }

    const printerListMarkup = printerList.map((line: any, index: any) => {
        if (line?.id === "e6a489d7-d9cc-4a68-a31f-ea1f497b7672") {
            return (
                <PrinterItem key={index} setShow={setShow} {...line} />
            )
        }
    })
    return (
        // @ts-ignore
        <Dialog
            isOpen={show}
            containerClassName={'lg:max-w-full lg:w-[650px]'}
            onDismiss={closeHandler}
            dialogContentProps={dialogContentProps}>

            <div className={"flex flex-col gap-2 max-h-[50vh] overflow-y-scroll overflow-x-hidden"}>
                {!_.isEmpty(printerList) ? printerListMarkup : <NoPrinters />}
            </div>

            <DialogFooter>
                <DefaultButton onClick={closeHandler} text="Cancel" />
            </DialogFooter>
        </Dialog>
    )
}

export default PrinterListDialog