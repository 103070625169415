import env from '../../../package.json';
import {useToast} from "@chakra-ui/react";
import {APP_CONSTS} from "../../consts";
import {getPrinters} from "../../services/printix-api.service";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import InvoiceListV2 from "./components/InvoiceListV2";
import {setWindowKey} from "../../services/window.service";
import PrintedListV2 from "./components/PrintedListV2";
import PrintingStatus from "../../components/printing-status/PrintingStatus";
import {useLocalStore} from "@dladio/hooks";
import {PrinterListDialog} from "../../components";
import {printQueueRunnerV2} from "../../services/print-queue-runner-v2.service";
import {checkTokensValidity} from "../../utils";
import {useEffect, useState} from "react";
import {Box, DefaultCommandBar, Stack, TitleBar} from "@dladio/core-ui";

const PrintQueueV2 = () => {
    const dispatch: any = useDispatch()
    const navigate = useNavigate()
    const [showPrinterDialog, setShowPrinterDialog] = useState<boolean>(false)
    const {model: queueStatus} = useLocalStore("queue-status")
    const toast = useToast()

    useEffect(() => {
        // Token validations
        validateTokens().then()

        // Fetching printers
        dispatch(getPrinters() as any)
    }, [JSON.stringify(checkTokensValidity())])

    setInterval(function () {
        dispatch(getPrinters() as any)
    } as any, 100000)

    const cmdBarConfig = [
        {
            key: 1,
            text: "back",
            iconProps: {iconName: 'Back'},
            onClick: () => navigate(-1)
        },
        {
            key: 2,
            text: 'Start',
            disabled: (queueStatus == APP_CONSTS.PRINT_QUEUE_STATUS.IN_PROGRESS),
            iconProps: {iconName: 'SwitcherStartEnd'},
            onClick: async () => {
                await printQueueRunnerV2(dispatch, toast)
            },
        },
        {
            key: 3,
            text: 'Stop',
            disabled: !(queueStatus == APP_CONSTS.PRINT_QUEUE_STATUS.IN_PROGRESS),
            iconProps: {iconName: 'StopSolid'},
            onClick: async () => {
                toast({
                    title: 'Terminating printing process!',
                    status: 'error',
                    duration: 6000,
                    position: "bottom",
                    isClosable: true,
                })
                setWindowKey(APP_CONSTS.WINDOW_KEYS.EMERGENCY_BREAK, true)
            },
        }
    ]

    const validateTokens = async () => {
        await checkTokensValidity()
    }

    return (
        <>
            <TitleBar title="DLAD Print Queue V2"
                      subTitle={`Helios core theme v${env?.dependencies?.["@dladio/core-ui"]?.replace('^', '')}`}/>
            <DefaultCommandBar actions={cmdBarConfig}/>

            <Stack>
                <Box title="Invoices">
                    <InvoiceListV2/>
                </Box>

                <Box title="Printing Status">
                    <PrintingStatus setShowPrinterDialog={setShowPrinterDialog}/>
                </Box>

                <Box title="Printed List">
                    <PrintedListV2/>
                </Box>
            </Stack>

            <PrinterListDialog
                show={showPrinterDialog}
                setShow={setShowPrinterDialog}/>
        </ >
    );
}


export default PrintQueueV2