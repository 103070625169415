import _ from "lodash"
import {NoData} from "../../../components";
import {QUERIES} from "../../../consts";
import {useDerive} from "@dladio/service"
import InvoiceItem from "../../../components/invoice-list/components/InvoiceItem";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts"

const PrintedList = () => {
    const response = useDerive('exec', {
        options: {
            method: EXEC_QUERY_METHOD,
            body: {
                name: QUERIES.GET_PRINTED_LIST_V2,
            }
        }, path: 'message'
    })

    let invoiceList = (!_.isEmpty(response) && response?.data) ? _.get(response, "data.message") : []
    let invoiceMarkup = invoiceList.map((line: any, index: any) => <InvoiceItem key={index} {...line} />)

    return (
        <div className="h-[calc(100vh-153px)] flex flex-col gap-2 overflow-x-hidden overflow-y-auto">
            {invoiceMarkup}
            {_.isEmpty(invoiceList) && <NoData/>}
        </div>
    )
}

export default PrintedList