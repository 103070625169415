import { accessTokenIsValid, getAccessToken, getTenentToken } from "../services/local-storage.service";
import { login } from "../services/printix-api.service";

async function checkTokensValidity() {
    if (getAccessToken() && accessTokenIsValid() && getTenentToken()) {
        return true
    } else {
        await login()
        return true
    }
}

export { checkTokensValidity };