import _ from "lodash";
import {activityUpdater} from "./activity-updater.service";
import {getReportBase64} from "./pdf-generator/pdf-generator.service";
import {SAP_PRINT_STATUS} from "../consts/sap-line-status";
import {getWindowKey, setWindowKey} from "./window.service";
import {getLocalStoreThunk, localStoreThunk} from "@dladio/hooks";
import {APP_CONSTS, PRINT_QUERIES, PRINT_TEMPLATES} from "../consts";
import {completeJob, getCurrentQueueDocEntry, sapUpdater, submitJob, uploadFile} from "./printix-api.service";

const {PACKING_SLIP, SALES_INVOICE} = PRINT_TEMPLATES
const {SALES_INVOICE_PRINT_QUERY, PACKING_SLIP_PRINT_QUERY} = PRINT_QUERIES
const {EMERGENCY_BREAK, RUNNING_PRINT_QUEUE_LENGTH} = APP_CONSTS.WINDOW_KEYS
const {PDF_GENERATING_ERROR, FILE_UPLOADING_ERROR, JOB_COMPLETION_ERROR, JOB_CREATING_ERROR} = APP_CONSTS.ACTIVITY_STATUS
const toastConfig = {
    title: 'Print queue is empty!',
    description: "Please select documents that you want to print",
    status: 'error',
    duration: 5000,
    position: "bottom",
    isClosable: true,
}

export const printQueueRunner = async (dispatch: any, toast: any) => {
    const {setStore} = localStoreThunk("queue-status")
    const {setStore: setPrintix} = localStoreThunk("printix")
    const _printQueue: any = await dispatch(getLocalStoreThunk("printQueue", "printQueue"))
    let printQueue = !_.isEmpty(_printQueue) ? _printQueue : []

    if (printQueue?.length == 0 || _.isEmpty(printQueue)) {
        toast(toastConfig)
        return true
    }

    const getPrintTemplate = (type: any) => {
        if (type == "SI") {
            return [SALES_INVOICE, SALES_INVOICE_PRINT_QUERY]

        } else if (type == "PS") {
            return [PACKING_SLIP, PACKING_SLIP_PRINT_QUERY]
        }
    }

    // Clear job history
    dispatch(setPrintix("jobs", []))

    dispatch(getCurrentQueueDocEntry()).then(async (queueDocEntry: any) => {
        dispatch(setStore(APP_CONSTS.PRINT_QUEUE_STATUS.IN_PROGRESS))
        setWindowKey(EMERGENCY_BREAK, false)
        setWindowKey(RUNNING_PRINT_QUEUE_LENGTH, printQueue.length)

        for (let i = 0; i < printQueue.length; i++) {
            if (getWindowKey(EMERGENCY_BREAK) == true) {
                break;
            }

            const docEntry = printQueue[i]['DocEntry']
            const invoiceNumber = printQueue[i]['DocNumber']
            const docNumber = invoiceNumber.split("-")[1]
            const lineId = printQueue[i]['LineId']
            const printType = printQueue[i]["PrintType"]
            const [PRINTING_TEMPLATE, PRINTING_QUERY]: any = getPrintTemplate(printType)
            const {jobId, completeLink, uploadLink} = await dispatch(submitJob(invoiceNumber) as any)

            if (jobId && completeLink && uploadLink) {
                // PDF generating
                let pdfResult = null;
                try {
                    pdfResult = await dispatch(getReportBase64(jobId, docEntry, docNumber, {
                        query: PRINTING_QUERY,
                        key: 'DocNum',
                        path: 'message'
                    }, PRINTING_TEMPLATE))
                } catch (error: any) {
                    dispatch(activityUpdater(jobId, PDF_GENERATING_ERROR))
                    await dispatch(sapUpdater(SAP_PRINT_STATUS.PDF_GENERATING_ERROR, error.message, queueDocEntry, lineId, jobId))
                    continue;
                }

                // File uploading
                let fileUploadStatus = null;
                try {
                    fileUploadStatus = await dispatch(uploadFile(jobId, pdfResult, uploadLink) as any)
                } catch (error: any) {
                    dispatch(activityUpdater(jobId, FILE_UPLOADING_ERROR))
                    await dispatch(sapUpdater(SAP_PRINT_STATUS.UPLOADING_ERROR, error.message, queueDocEntry, lineId, jobId))
                    continue;
                }

                if (fileUploadStatus) {
                    try {
                        await dispatch(completeJob(jobId, completeLink) as any)
                        await dispatch(sapUpdater(SAP_PRINT_STATUS.JOB_DONE, "", queueDocEntry, lineId, jobId))

                    } catch (error: any) {
                        dispatch(activityUpdater(jobId, JOB_COMPLETION_ERROR))
                        await dispatch(sapUpdater(SAP_PRINT_STATUS.JOB_ERROR, error.message, queueDocEntry, lineId, jobId))
                    }

                } else {
                    dispatch(activityUpdater(jobId, FILE_UPLOADING_ERROR))
                    continue;
                }

            } else {
                dispatch(activityUpdater(jobId, JOB_CREATING_ERROR))
                await dispatch(sapUpdater(SAP_PRINT_STATUS.JOB_CREATING_ERROR, "", queueDocEntry, lineId, jobId))
                continue;
            }
        }

        dispatch(setStore(APP_CONSTS.PRINT_QUEUE_STATUS.AVAILABLE))
        setWindowKey(EMERGENCY_BREAK, false)

    }).catch(() => {
        console.log("error");
    })
}