import _ from "lodash";
import {APP_CONSTS} from "../../consts";
import {getWindowKey} from "../../services/window.service";
import {useLocalStore} from "@dladio/hooks";
import SelectedPrinter from "./components/SelectedPrinter";
import {Spinner, SpinnerSize} from "@fluentui/react";
import {CheckCircleIcon, InfoIcon} from "@chakra-ui/icons";

const PrintingStatus = ({setShowPrinterDialog}: any) => {
    const {select} = useLocalStore("printix")
    let jobs = !_.isEmpty(select('jobs')) ? select("jobs") : []
    const {IN_PROGRESS, SUCCESS} = APP_CONSTS.LOCAL_JOB_STATUS
    const {RUNNING_PRINT_QUEUE_LENGTH} = APP_CONSTS.WINDOW_KEYS

    const statusGenerator = (item: any, index: any) => {
        try {
            const statuses = item.split(" - ")

            if (statuses?.length === 2) {
                const statusCode = statuses[1]
                const statusText = statuses[0]

                if (statusCode === "SUCCESS") {
                    return (
                        <div key={index} className="flex gap-2 items-center">
                            <h1 className="text-[11px]">{statusText}</h1>
                            <CheckCircleIcon color={"green.500"} w={3}/>
                        </div>
                    )
                } else {
                    return (
                        <div key={index} className="flex gap-2 items-center">
                            <h1 className="text-[11px]">{statusText}</h1>
                            <InfoIcon color={"red.500"} w={3}/>
                        </div>
                    )
                }
            } else {
                return <h1 key={index} className="text-[11px]">Status update error!</h1>
            }
        } catch (error: any) {
            return <h1 className="text-[11px]">Status update error!</h1>
        }
    }

    const localPrintStatusGenerator = (status: any) => {
        if (status == IN_PROGRESS) {
            return <h1 className={"text-[9px] bg-yellow-500 text-white font-semibold rounded px-1"}>{status}</h1>
        } else if (status == SUCCESS) {
            return <h1 className={"text-[9px] bg-green-500 text-white font-semibold rounded px-1"}>{status}</h1>
        } else {
            return <h1 className={"text-[9px] bg-red-500 text-white font-semibold rounded px-1"}>{status}</h1>
        }
    }

    const getStatus = (status: "SUCCESS" | "FAILED") => {
        let res = _.filter(jobs, {"status": status})
        return res?.length || 0
    }

    const activityMarkup = jobs.map((line: any, index: any) => (
        <div key={index} className="w-full border rounded flex flex-col  bg-gray-200 pb-3">
            <div className={"w-full border relative p-3 rounded flex justify-between items-center"}>
                <div className="flex items-center gap-1">
                    <h1 className="text-[10px] bg-orange-500 text-white p-0.5 rounded-full w-[20px] flex justify-center items-center">{index + 1}</h1>
                    <div className="flex flex-col">
                        <div className="flex items-center gap-2">
                            <h1 className="text-[12px] font-semibold mt-0.5">{line.title}</h1>
                            {line.status == IN_PROGRESS && <Spinner size={SpinnerSize.xSmall}/>}
                        </div>
                        <h1 className="text-[10px] mt-0.5 truncate">{line.jobId}</h1>
                    </div>
                </div>
                {localPrintStatusGenerator(line.status)}
            </div>

            <div className="border-t border-gray-400"></div>

            <div className="px-3 flex flex-col gap-1 justify-center mt-3">
                {(!_.isElement(line?.activities) && line?.activities) ? line.activities.map((item: any, index: any) => {
                    return statusGenerator(item, index)
                }) : null}
            </div>
        </div>
    ))

    return (
        <div>
            <div className="bg-orange-200 mt-2 p-3 rounded border border-yellow-500 flex justify-between items-center">
                <h1>{`${jobs?.length ?? 0} of ${getWindowKey(RUNNING_PRINT_QUEUE_LENGTH) || 0} is completed`}</h1>
                <div className="flex items-center gap-2">
                    <SuccessCount count={getStatus("SUCCESS")}/>
                    <FailedCount count={getStatus("FAILED")}/>
                </div>
            </div>

            <SelectedPrinter setShowPrinterDialog={setShowPrinterDialog}/>

            <div
                className={"mt-4 flex flex-col items-center gap-2 h-[calc(100vh-304px)] overflow-x-hidden overflow-y-auto"}>
                {activityMarkup}
            </div>
        </div>
    )
}

const FailedCount = ({count}: any) => {

    return (
        <div className={`flex items-center gap-2 bg-red-500 px-1 rounded text-white ${count == 0 ? "hidden" : ''}`}>
            <h1 className="text-[11px]">Failed</h1>
            <h1 className="text-[11px]">{count}</h1>
        </div>
    )
}

const SuccessCount = ({count}: any) => {

    return (
        <div className={`flex items-center gap-2 bg-green-500 px-1 rounded text-white ${count == 0 ? "hidden" : ''}`}>
            <h1 className="text-[11px]">Success</h1>
            <h1 className="text-[11px]">{count}</h1>
        </div>
    )
}

export default PrintingStatus