import { EXEC_QUERY_METHOD } from "@dladio/service/dist/const/erp-method.consts";
import { Providers } from "@microsoft/mgt-element";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import { APP_CONSTS } from "../../consts";
import { activityUpdater } from "../activity-updater.service";
import { derive } from "./derive.action";
import msGraphApi from "./ms-graph.service";
import { angularParser, loadFile } from "./templating-helpers";

export interface IFetchPrintModel {
    query: string,
    key: string,
    path: string
}

const FILE_BASE = '/print-templates'
const MIME = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

export const createDocAction = (jobId: any, content: any, docNum: any) => {
    return async (dispatch: any) => {
        const accessToken = await Providers.globalProvider.getAccessTokenForScopes('Files.ReadWrite.All')
        const url = `/drive/root:/sap-data/SO-${docNum}.docx:/content`

        let response = await dispatch(msGraphApi.endpoints.execPut.initiate({
            url,
            body: content,
            accessToken
        }))

        return response
    }
}

export const getPDFofDocAction = (jobId: any, docNum: any) => {
    return async (dispatch: any) => {
        const accessToken = await Providers.globalProvider.getAccessTokenForScopes('Files.ReadWrite.All')
        const url = `${APP_CONSTS.GRAPH_BASE}v1.0/me/drive/root:/sap-data/SO-${docNum}.docx:/content?format=pdf`

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${accessToken}`
            },
            referrerPolicy: 'no-referrer',
        });

        return response
    }
}


export const getReportBase64 = (jobId: any, docEntry: any, docNum: any, fetchPrintModel: IFetchPrintModel, reportName: any) => {
    return async (dispatch: any) => {

        // Get Print Model
        let printModel: any = await dispatch(derive('erp', {
            options: {
                method: EXEC_QUERY_METHOD,
                body: { name: fetchPrintModel.query, id: docEntry },
            },
            path: fetchPrintModel.path
        }))

        dispatch(activityUpdater(jobId, APP_CONSTS.ACTIVITY_STATUS.MODEL_PREPARING_SUCCESS))

        let base: any = await (new Promise((resolve, reject) => {
            loadFile(`${FILE_BASE}/${reportName}`,
                async (error: any, content: any) => {

                    if (error) {
                        throw error;
                    }

                    const zip = new PizZip(content);
                    const doc = new Docxtemplater(zip, {
                        parser: angularParser, linebreaks: true
                    });

                    doc.render(printModel);

                    const out = doc.getZip().generate({
                        type: "blob",
                        mimeType: MIME,
                    });

                    await dispatch(createDocAction(jobId, out, docNum))

                    let pdf = await dispatch(getPDFofDocAction(jobId, docNum))

                    await pdf.blob().then(async (pdf: any) => {

                        const blobToBase64 = async (blob: any) => {
                            const reader = new FileReader();
                            await reader.readAsDataURL(blob);
                            return new Promise(resolve => {
                                reader.onloadend = () => {
                                    resolve(reader.result);
                                };
                            });
                        };

                        let base64 = await blobToBase64(pdf)
                        resolve(base64)

                    })
                }
            );

        }))

        dispatch(activityUpdater(jobId, APP_CONSTS.ACTIVITY_STATUS.PDF_GENERATING_SUCCESS))

        return base.replace(/^data:.+;base64,/, '')
    }
}