import { LoginType, Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';


Providers.globalProvider = new Msal2Provider({
  clientId: '13a9c143-e583-4cbb-adbb-76a7ce379132',
  loginType: LoginType.Popup,
  scopes: [
    'calendars.read',
    'user.read',
    'openid',
    'profile',
    'people.read',
    'user.readbasic.all',
    'Mail.ReadWrite',
    'Team.ReadBasic.All',
    'Chat.ReadWrite',
    'Files.ReadWrite.All',
    'Mail.Send'
  ]
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
