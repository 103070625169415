const InvoiceItem = ({CardName, DocDate, DocNumber, DocTotal, PrintType}: any) => {

    return (
        <div className={"rounded border w-full p-3"}>
            <div className="flex justify-between items-start">
                <div>
                    <h1 className={"font-bold"}>{DocNumber}</h1>
                    <h1>{DocDate}</h1>
                    <h1>{CardName}</h1>
                    <h1>{DocTotal}</h1>
                </div>

                <h1 className="bg-orange-400 text-white font-semibold px-2 rounded-md">{PrintType}</h1>
            </div>
        </div>
    )
}

export default InvoiceItem