const PRINTIX_CLIENT_ID = "c6fe2c73-0617-4f4e-bade-2f788748cce3"
const PRINTIX_CLIENT_SECRET = "nFYkealyaaH7WLRsLsvVaTB1Rl3tWSvmfP6J7VT07sPEv6uy"
const GRAPH_BASE = "https://graph.microsoft.com/"

const ACCESS_TOKEN = "ACCESS_TOKEN"
const REFRESH_TOKEN = "REFRESH_TOKEN"
const EXPIRATION_TIME = "EXPIRATION_TIME"
const TENANT_ID = "TENANT_ID"
const DEFAULT_PRINTER = "DEFAULT_PRINTER"

const DEFAULT_SI_TEMPLATE = "DEFAULT_SI_TEMPLATE"
const DEFAULT_PI_TEMPLATE = "DEFAULT_PI_TEMPLATE"

const SHOW_PS = "SHOW_PS"
const SHOW_SI = "SHOW_SI"
const SHOW_DN = "SHOW_DN"

enum PRINT_QUEUE_STATUS {
    AVAILABLE = "AVAILABLE",
    IN_PROGRESS = "IN_PROGRESS",
}

enum LOCAL_JOB_STATUS {
    IN_PROGRESS = "IN PROGRESS",
    FAILED = "FAILED",
    SUCCESS = "SUCCESS"
}

enum ACTIVITY_STATUS {
    PDF_GENERATING_ERROR = "PDF Generating... - FAILED",
    PDF_GENERATING_SUCCESS = "PDF Generating... - SUCCESS",

    FILE_UPLOADING_ERROR = "File uploading... - FAILED",
    FILE_UPLOADING_SUCCESS = "File uploading... - SUCCESS",

    JOB_COMPLETION_ERROR = "Job completion... - FAILED",
    JOB_COMPLETION_SUCCESS = "Completing job... - SUCCESS",

    JOB_CREATING_ERROR = "Creating a job... - FAILED",
    JOB_CREATING_SUCCESS = "Creating a job... - SUCCESS",

    MODEL_PREPARING_SUCCESS = "Preparing print model... - SUCCESS"
}

const PRINTER_STATUS = {
    UNKNOWN: "UNKNOWN",
    WARNING: "WARNING",
    ONLINE: "ONLINE"
}

const COMPLETION_STATUS = {
    UPLOAD_STATUS: "UPLOAD_STATUS",
    COMPLETE_STATUS: "COMPLETE_STATUS",
    JOB_STATUS: "JOB_STATUS",
    ERROR_STATUS: "ERROR_STATUS"

}

enum WINDOW_KEYS {
    EMERGENCY_BREAK = "EMERGENCY_BREAK",
    RUNNING_PRINT_QUEUE_LENGTH = "RUNNING_PRINT_QUEUE_LENGTH"
}

export default {
    PRINTIX_CLIENT_ID,
    PRINTIX_CLIENT_SECRET,
    GRAPH_BASE,
    ACCESS_TOKEN,
    REFRESH_TOKEN,
    EXPIRATION_TIME,
    TENANT_ID,
    DEFAULT_PRINTER,
    PRINTER_STATUS,
    DEFAULT_PI_TEMPLATE,
    DEFAULT_SI_TEMPLATE,
    SHOW_PS,
    SHOW_SI,
    SHOW_DN,
    PRINT_QUEUE_STATUS,
    COMPLETION_STATUS,
    LOCAL_JOB_STATUS,
    ACTIVITY_STATUS,
    WINDOW_KEYS
}