import _ from "lodash";
import {setItem} from "../../../services/local-storage.service";
import {APP_CONSTS} from "../../../consts";
import PrinterStatus from "./PrinterStatus";
import {useLocalStore} from "@dladio/hooks";
import {CheckCircleIcon} from "@chakra-ui/icons";

interface PrinterItemProps {
    id: string,
    connectionStatus: any,
    name: string,
    setShow: any,
    _links?: any
}

const PrinterItem = ({id, setShow = () => {}, connectionStatus = "UNKNOWN", name, _links}: PrinterItemProps) => {
    const {UNKNOWN, WARNING, ONLINE} = APP_CONSTS.PRINTER_STATUS
    const {select, setStore} = useLocalStore("printix")
    let printer = select("selectedPrinter")

    const statusGenerator = () => {
        if (connectionStatus === WARNING) {
            return <PrinterStatus status={WARNING} color={"text-yellow-700"}/>
        } else if (connectionStatus === ONLINE) {
            return <PrinterStatus status={ONLINE} color={"text-green-700"}/>
        } else {
            return <PrinterStatus status={UNKNOWN} color={"text-red-700"}/>
        }
    }

    const printerSelector = () => {
        const submitLink = _links.submit.href
        const selectingPrinter = {id, name, connectionStatus, submitLink}
        setItem(APP_CONSTS.DEFAULT_PRINTER, JSON.stringify(selectingPrinter))
        setStore("selectedPrinter", selectingPrinter)
        setShow(false)
    }

    return (
        <div onClick={printerSelector}
             className={"p-3 cursor-pointer border w-full rounded shadow flex justify-between items-center"}>
            <div className={"flex items-center"}>
                {(!_.isEmpty(printer) && printer?.id === id) ?
                    <CheckCircleIcon color={"green.500"}/> :
                    <CheckCircleIcon color={"blackAlpha.400"}/>}

                <div className={"ml-2"}>
                    <h1>{name}</h1>
                    <h1 className={"text-xs"}>{id}</h1>
                </div>
            </div>
            {statusGenerator()}
        </div>
    )
}

export default PrinterItem